import React, { useEffect, useState } from 'react';

import '../style/page.scss';
import ReviewTable from '../components/reviewTable';
import Page from '../components/page';
import { useBoards, useOrganizations, useReviews } from '../hooks';
import { serializeData } from '../utils/serialization';
import { generateQuery } from '../utils/reviews';
import { ROWS_PER_PAGE, SORT_ORDER } from '../constants';

const ReviewsPage = () => {
  const { getBoards } = useBoards();
  const { getOrganizations } = useOrganizations();
  const { isFetchingReviews, getReviews } = useReviews();
  const [boards, setBoards] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [reviews, setReviews] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let mounted = true;
    (async () => {
      // fetch boards
      const res = await getBoards({
        getActiveReviewCounts: false,
        getImages: false,
        includePosition: true,
      });
      if (mounted && res && res.data) {
        const arr = [{ abbr: 'All', label: 'All' }];
        res.data.forEach(board => {
          arr.push({
            abbr: board.abbreviation.toLowerCase(),
            label: board.abbreviation,
          });
        });
        setBoards(arr);
      }
    })();

    (async () => {
      // fetch organizations
      const orgs = await getOrganizations();
      if (mounted && orgs && orgs.data) {
        setOrganizations(orgs.data);
      }
    })();

    // fetch reviews
    (async () => {
      const currentState = history.state;
      // fetch reviews from the beginning using pagination/sorting from the history.state if available
      await fetchReviews(
        generateQuery(
          currentState?.searchFilter || undefined,
          currentState?.typeFilter || undefined,
          currentState?.statusFilter || undefined,
          currentState?.dateFilter || undefined,
          currentState?.organizationFilter || undefined,
          currentState?.currentPage
            ? (currentState?.currentPage - 1) * ROWS_PER_PAGE
            : undefined,
          currentState?.sortCol ? currentState?.sortCol : undefined,
          currentState?.order ? SORT_ORDER[currentState?.order] : undefined,
        ),
      );
    })();

    return () => (mounted = false);
  }, []);

  const fetchReviews = async (query: string) => {
    setReviews(null);
    const res = await getReviews(query);
    if (res && res.data) {
      setCount(res.data.count);
      setReviews(await serializeData(res.data.reviews));
    }
  };

  return (
    <Page isFetching={false}>
      <ReviewTable
        isFetchingReviews={isFetchingReviews}
        organizations={organizations}
        boards={boards}
        reviews={reviews}
        totalReviewsCount={count}
        onUpdate={async query => await fetchReviews(query)}
      />
    </Page>
  );
};

export default ReviewsPage;
